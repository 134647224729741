import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Reports from './pages/Reports';

function App() {
  const token = localStorage.getItem("token");

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };


  return (
    <Router>
      <nav>
        {!token ? (
          <>
            <Link to="/register">Register</Link> | <Link to="/login">Login</Link>
          </>
        ) : (
          <>
            <Link to="/dashboard">Dashboard</Link> | 
            <Link to="/reports">Reports</Link> |
            <button 
              onClick={handleLogout} 
              style={{
                background: 'none', 
                border: 'none', 
                color: 'blue', 
                cursor: 'pointer', 
                textDecoration: 'underline'
              }}
            >
              Logout
            </button>
          </>
        )}
      </nav>

      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/" element={<h2>Welcome to the App</h2>} />
      </Routes>
    </Router>
  );
}

export default App;
