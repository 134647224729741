import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Reports() {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchFiles();
    }
  }, [token, navigate]);

  const fetchFiles = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/files/reports`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFiles(data.files);
    } catch (error) {
      console.error("Error fetching reports:", error);
      alert("Failed to fetch reports. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>User Reports</h2>
      {loading ? (
        <p>Loading...</p>
      ) : files.length === 0 ? (
        <p>No files found.</p>
      ) : (
        <ul>
          {files.map(file => (
            <li key={file.id}>
              {file.filename} - Uploaded on {new Date(file.uploaded_at).toLocaleString()}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Reports;

