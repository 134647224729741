import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function Register() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        email, 
	username, 
	password,
        company_name: companyName || null,
        phone_number: phoneNumber || null,
      });

      localStorage.setItem('token', data.access_token);
      alert('Registeration successfull. You will be redirected to the dashboard page!!!');
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      alert('Registration failed.');
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <h2>Register</h2>
      <div>
        <label>Email:</label>
        <input value={email} onChange={(e) => setEmail(e.target.value)} required />
      </div>
      <div>
        <label>Username:</label>
        <input value={username} onChange={(e) => setUsername(e.target.value)} required />
      </div>
      <div>
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </div>
      <div>
        <label>Company Name (Optional):</label>
        <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
      </div>
      <div>
        <label>Phone Number (Optional):</label>
        <input
          placeholder="+<country_code><number>"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>
      <button type="submit">Register</button>
    </form>
  );
}

export default Register;

